<template>
  <div>
    <div class="base">
      <h1>アカウント詳細登録</h1>
      <div class="name">
        <p>お名前</p>
        <input type="name" v-model="name" placeholder="田中 太郎" />
      </div>
      <div class="number">
        <p>郵便番号</p>
        <input type="number" v-model="number" placeholder="000 0000" />
      </div>
      <div class="address">
        <p>住所</p>
        <input type="address" v-model="address" placeholder="○○県○○市○○町○○マンション○○号室" />
      </div>
      <div class="phone">
        <p>お電話番号</p>
        <input type="phone" v-model="phone" placeholder="0120 000 000" />
      </div>
      <p class="error">{{ error }}</p>
      <div @click="sub()" class="sub">登録</div>
    </div>
  </div>
</template>

<script>
import firebase from '@/firebase/firebase'

export default {
  name: 'Address',
  data () {
    return {
      db: null,
      dbName: null,
      userEmail: '',
      user: null,
      phone: '',
      load: 0,
      name: '',
      address: '',
      number: '',
      error: ''
    }
  },
  created () {
    this.db = firebase.firestore()
    this.dbName = firebase.firestore()
    firebase.auth().onAuthStateChanged((user) => {
      this.userEmail = user.email
      this.user = user.uid
    })
  },
  methods: {
    sub () {
      if (this.name === '') {
        this.error = '名前が入力されていません'
      } else if (this.number === '') {
        this.error = '郵便番号が入力されていません'
      } else if (this.address === '') {
        this.error = '住所が入力されていません'
      } else if (this.phone === '') {
        this.error = '電話番号が入力されていません'
      } else {
        this.dbName
          .collection('customers')
          .doc(this.user)
          .update({
            number: this.number,
            name: this.name,
            address: this.address,
            phone: this.phone
          })
        this.number = ''
        this.name = ''
        this.address = ''
        this.phone = ''
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped>
.base {
  background-color: rgba(120, 120, 120, 0.5);
  width: 600px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.error {
  color: red;
}

.name,
.address,
.phone,
.number {
  width: 500px;
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  letter-spacing: 2px;
}

.name input,
.phone input,
.number input {
  height: 25px;
  background-color: rgba(0, 0, 0, 0);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid black;
  padding-left: 10px;
}

::placeholder {
  color: rgba(255, 255, 255, 0.568);
  letter-spacing: 1px;
}

.address input {
  height: 25px;
  width: 250px;
  background-color: rgba(92, 92, 92, 0);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid black;
  padding-left: 10px;
}

.sub {
  border: 1px solid black;
  width: 100px;
  text-align: center;
  margin: 30px auto;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
</style>
